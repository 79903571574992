body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.bg-primary {
    background-color: purple !important;
}

.no-margin {
    margin: 0;
}

.btn-outline-primary {
    --bs-btn-color: purple;
    --bs-btn-border-color: purple;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: purple;
    --bs-btn-hover-border-color: purple;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: purple;
    --bs-btn-active-border-color: purple;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: purple;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: purple;
    --bs-gradient: none;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: purple;
    --bs-btn-border-color: purple;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: purple;
    --bs-btn-hover-border-color: purple;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: purple;
    --bs-btn-active-border-color: purple;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: purple;
    --bs-btn-disabled-border-color: purple;
}

.card {
    margin-bottom: 1%;
    margin-top: 1%;
}

.badge {
    cursor: pointer;
}

.date-list {
    border: none;
}

.list-group {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
}

.list-group .list-group-item:first-child {
    margin-left: auto;
}

.list-group .list-group-item:last-child {
    margin-right: auto;
}

.form-check-input {
    border-color: #000
}